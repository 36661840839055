import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './AdminProductList.css';

const AdminProductList = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
            setProducts(res.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/products/${id}`);
            setProducts(products.filter(product => product._id !== id));
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    const handleAddProduct = () => {
        navigate('/admin/add-product');
    };

    const handleEditProduct = (id) => {
        navigate(`/admin/edit-product/${id}`);
    };

    return (
        <div className="product-grid-container">
            <div className="header">
                <h2>Ürünler</h2>
                <button className="btn btn-primary" onClick={handleAddProduct}>Ürün Ekle</button>
            </div>
            <div className="product-grid">
                {products.map(product => (
                    <div className="product-card" key={product._id}>
                        <img src={product.mainImageUrl} alt={product.name} className="product-image" />
                        <div className="product-info">
                            <h5>{product.name}</h5>
                            <p>${product.price}</p>
                        </div>
                        <div className="product-actions">
                            <button className="btn btn-warning" onClick={() => handleEditProduct(product._id)}>Düzenle</button>
                            <button className="btn btn-danger" onClick={() => handleDelete(product._id)}>Sil</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminProductList;
