import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import './AdminProducts.css';

const shoeSizes = [
    { value: '35', label: '35' },
    { value: '35.5', label: '35.5' },
    { value: '36', label: '36' },
    { value: '36.5', label: '36.5' },
    { value: '37', label: '37' },
    { value: '37.5', label: '37.5' },
    { value: '38', label: '38' },
    { value: '38.5', label: '38.5' },
    { value: '39', label: '39' },
    { value: '39.5', label: '39.5' },
    { value: '40', label: '40' },
    { value: '40.5', label: '40.5' },
    { value: '41', label: '41' },
    { value: '41.5', label: '41.5' },
    { value: '42', label: '42' },
    { value: '42.5', label: '42.5' },
    { value: '43', label: '43' }
];

const AddProduct = () => {
    const [product, setProduct] = useState({
        name: '',
        price: '',
        description: '',
        stock: '',
        images: [],
        collection: '',
        sizes: [],
        colors: [], // Yeni renk seçeneği ekleme
        mainImageIndex: 0
    });
    const [collections, setCollections] = useState([]);
    const [newColorInput, setNewColorInput] = useState(''); // Yeni renk girişi için state
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchProduct();
        }
        fetchCollections();
    }, [id]);

    const fetchProduct = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`);
            const { name, price, description, stock, imageUrls, collection, sizes, colors, mainImageUrl } = res.data;
            setProduct({
                name,
                price,
                description,
                stock,
                images: imageUrls,
                collection,
                sizes: sizes.map(size => ({ value: size, label: size })),
                colors: colors.map(color => ({ value: color, label: color })), // Renkleri set et
                mainImageIndex: imageUrls.indexOf(mainImageUrl)
            });
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    const fetchCollections = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/collections`);
            setCollections(res.data);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    const handleChange = (e) => {
        if (e.target.name === 'images') {
            setProduct({ ...product, images: Array.from(e.target.files) });
        } else {
            setProduct({ ...product, [e.target.name]: e.target.value });
        }
    };

    const handleSizeChange = (selectedOptions) => {
        setProduct({ ...product, sizes: selectedOptions });
    };

    const handleColorChange = (selectedOptions) => {
        setProduct({ ...product, colors: selectedOptions });
    };

    const handleImageClick = (index) => {
        setProduct({ ...product, mainImageIndex: index });
    };

    const handleNewColorInput = (e) => {
        const value = e.target.value;
        if (value.includes(',')) {
            const newColors = value.split(',').map(color => color.trim()).filter(color => color);
            if (newColors.length > 0) {
                setProduct(prev => ({
                    ...prev,
                    colors: [
                        ...prev.colors,
                        ...newColors.map(color => ({ value: color, label: color }))
                    ]
                }));
                setNewColorInput(''); // Giriş kutusunu temizle
            }
        } else {
            setNewColorInput(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', product.name);
        formData.append('price', product.price);
        formData.append('description', product.description);
        formData.append('stock', product.stock);
        formData.append('collection', product.collection);
        formData.append('sizes', JSON.stringify(product.sizes.map(size => size.value)));
        formData.append('colors', JSON.stringify(product.colors.map(color => color.value))); // Renkleri ekleme
        formData.append('mainImageIndex', product.mainImageIndex);
        product.images.forEach(image => {
            formData.append('images', image);
        });

        try {
            if (id) {
                await axios.put(`${process.env.REACT_APP_API_URL}/products/${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Product updated:', id);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/products`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Product added:', formData);
            }
            navigate('/admin/products');
        } catch (error) {
            console.error('Error saving product:', error);
        }
    };

    return (
        <div className="add-product-container">
            <h2>{id ? 'Ürünü Düzenle' : 'Ürün Ekle'}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Ürün İsmi</label>
                    <input
                        type="text"
                        name="name"
                        value={product.name}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Fiyat</label>
                    <input
                        type="number"
                        name="price"
                        value={product.price}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Açıklama</label>
                    <textarea
                        name="description"
                        value={product.description}
                        onChange={handleChange}
                        className="form-control"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Stok</label>
                    <input
                        type="number"
                        name="stock"
                        value={product.stock}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Boyutlar</label>
                    <Select
                        isMulti
                        name="sizes"
                        options={shoeSizes}
                        value={product.sizes}
                        onChange={handleSizeChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>
                <div className="form-group">
                    <label>Renkler</label>
                    <Select
                        isMulti
                        name="colors"
                        options={product.colors}
                        value={product.colors}
                        onChange={handleColorChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                    <input
                        type="text"
                        value={newColorInput}
                        onChange={handleNewColorInput}
                        placeholder="Yeni renk ekleyin ve virgül ile ayırın"
                        className="form-control mt-2"
                    />
                </div>
                <div className="form-group">
                    <label>Koleksiyon</label>
                    <select
                        name="collection"
                        value={product.collection}
                        onChange={handleChange}
                        className="form-control"
                    >
                        <option value="">Koleksiyon Seçin</option>
                        {collections.map((collection, index) => (
                            <option key={index} value={collection.name}>{collection.name}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label>Mevcut Resimler</label>
                    {product.images.map((image, index) => (
                        <img
                            key={index}
                            src={typeof image === 'string' ? image : URL.createObjectURL(image)}
                            alt="current"
                            className={`current-image ${product.mainImageIndex === index ? 'main-image' : ''}`}
                            onClick={() => handleImageClick(index)}
                        />
                    ))}
                </div>
                <div className="form-group">
                    <label>Yeni Resimler</label>
                    <input
                        type="file"
                        name="images"
                        onChange={handleChange}
                        className="form-control"
                        multiple
                    />
                </div>
                <button type="submit" className="btn btn-primary">{id ? 'Güncelle' : 'Ekle'}</button>
            </form>
        </div>
    );
};

export default AddProduct;
