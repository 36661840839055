import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import HeartButton from './HeartButton'; 
import './ProductCard.css';

const ProductCard = ({ product }) => {
    const navigate = useNavigate(); // Initialize the navigate function

    const handleNavigateToProduct = () => {
        navigate(`/product/${product._id}`);
    };

    return (
        <Card 
            sx={{ width: 320, maxWidth: '100%', boxShadow: 'lg', height: 450, cursor: 'pointer' }} 
            onClick={handleNavigateToProduct} // Navigate to the product detail page when the card is clicked
        >
            <CardOverflow sx={{ position: 'relative' }}>
                <AspectRatio ratio="20/19" sx={{ height: 250 }}>
                    <img
                        src={product.mainImageUrl || 'https://via.placeholder.com/320x200'} // Fallback image if URL is missing
                        alt={product.name}
                        loading="lazy"
                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                    />
                    <HeartButton 
                        productId={product._id} 
                        style={{ position: 'absolute', top: 8, right: 8 }} 
                        onClick={(e) => e.stopPropagation()} // Stop event propagation so the card click does not trigger
                    />
                </AspectRatio>
            </CardOverflow>
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography level="body-xs" sx={{ minHeight: 20,
                                                        justifyContent: 'center', 
                                                        alignItems: 'center', 
                                                        textAlign: 'center', 
                 }}>
                    
                    {product.description || ' '}
                </Typography>
                <Typography
                    level="body-md"
                    fontWeight="bold"
                    sx={{ mt: 1 ,                                    justifyContent: 'center', 
                        alignItems: 'center', 
                        textAlign: 'center', }}
                >
                    {product.name}
                </Typography>
                <Typography
                    level="title-lg"
                    sx={{ mt: 1, fontWeight: 'xl',                                    justifyContent: 'center', 
                        alignItems: 'center', 
                        textAlign: 'center',  }}
                >
                    {product.price} $
                </Typography>
                {product.stock <= 24 && (
                    <Typography level="body-sm" color="error" sx={{ mt: 1 ,                                    justifyContent: 'center', 
                        alignItems: 'center', 
                        textAlign: 'center',  }}>
                        (Only <b>{product.stock}</b> left in stock!)
                    </Typography>
                )}
            </CardContent>
            <CardOverflow>
                <Button 
                    variant="solid" 
                    color="primary" 
                    size="lg" 
                    sx={{ width: '100%' }}
                    onClick={(e) => {
                        e.stopPropagation(); // Stop event propagation so the card click does not trigger
                        handleNavigateToProduct();
                    }} 
                >
                    Add to cart
                </Button>
            </CardOverflow>
        </Card>
    );
};

export default ProductCard;
