import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';
import logo from './logo.png';

const Footer = () => {
    return (
        <footer className="footer mt-auto py-3 bg-dark text-white">
            <Container>
                <Row>
                    <Col md={3} className="mb-2 mb-md-0">
                        <div className="footer-logo">
                            <img src={logo}alt='Demmur Shoes Logo' />
                        </div>
                        <p>Demmur Shoes: Premium quality footwear for all your fashion needs.</p>
                    </Col>
                    <Col md={3} className="mb-4 mb-md-0">
                        <h5>Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="/">Home</a></li>
                            <li><a href="/wishlist">Wishlist</a></li>
                            <li><a href="/orders">Orders</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </Col>
                    <Col md={3} className="mb-4 mb-md-0">
                        <h5>Follow Us</h5>
                        <div className="social-icons">
                            <a href="https://www.facebook.com"><i className="fab fa-facebook-f"></i></a>
                            <a href="https://www.instagram.com"><i className="fab fa-instagram"></i></a>
                            <a href="https://www.twitter.com"><i className="fab fa-twitter"></i></a>
                        </div>
                        <div className="map">
                            <iframe
                                title="Demmur Shoes Location"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1959.0040660625113!2d28.97176891179549!3d41.01265620825244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caba5f10c1b3bb%3A0x753bbe7f8e4bffb7!2sTatlıkuyu%20Sokağı%20No%3A10%2C%2034130%20Fatih%2F%C4%B0stanbul!5e0!3m2!1str!2str!4v1638431561194!5m2!1str!2str"
                                width="100%"
                                height="150"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                        </div>
                    </Col>
                    <Col md={3} className="mb-4 mb-md-0">
                        <h5>Contact Us</h5>
                        <p>Address: Mimar Hayrettin, Tatlıkuyu Sokağı No:10, 34130 Fatih/İstanbul</p>
                        <p>Phone: +90 546 530 90 10</p>
                        <p>Email: info@demmurshoes.com</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
