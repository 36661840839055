import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Cart.css';

const Cart = () => {
  const [cart, setCart] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(''); 
  const [isOrderButtonActive, setIsOrderButtonActive] = useState(false); 

  useEffect(() => {
    fetchCartItems();
  }, []);

  const fetchCartItems = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found, please log in.');
            return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/cart`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        setCart(response.data);
    } catch (error) {
        console.error('Error fetching cart items:', error.response ? error.response.data : error.message);
    }
  };

  const updateQuantity = async (productId, size, quantity) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_URL}/cart/update`, { productId, size, quantity }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchCartItems(); // Refresh cart items
    } catch (error) {
      console.error('Error updating cart item:', error);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const phone = e.target.value;
    setPhoneNumber(phone);

    const phoneValid = phone.startsWith('+') && phone.length >= 10;
    setIsOrderButtonActive(phoneValid);
  };

  const handleCheckout = async () => {
    if (!isOrderButtonActive) return;

    try {
        const token = localStorage.getItem('token');
        const totalAmount = cart.items.reduce((total, item) => total + item.quantity * item.productId.price, 0);

        const orderData = {
            phoneNumber,
            cartItems: cart.items.map(item => ({
                productId: item.productId._id,
                size: item.size,
                quantity: item.quantity,
            })),
            totalAmount,
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/orders`, orderData, {
            headers: { Authorization: `Bearer ${token}` }
        });


        window.location.href = '/order-confirmation';
    } catch (error) {
        console.error('Sipariş oluşturulurken hata:', error);
    }
};


  if (!cart) return <div>Loading...</div>;

  return (
    <div className="cart-container">
      <h2>Shopping Cart</h2>
      {cart.items.map(item => (
        <div className="cart-item" key={`${item.productId._id}-${item.size}`}>
          <img src={item.productId.mainImageUrl} alt={item.productId.name} />
          <div className="cart-item-details">
            <h3>{item.productId.name}</h3>
            <p>{item.size}</p>
            <div className="cart-item-quantity">
              <button className="quantity-btn" onClick={() => updateQuantity(item.productId._id, item.size, item.quantity - 1)}>-</button>
              <span>{item.quantity}</span>
              <button className="quantity-btn" onClick={() => updateQuantity(item.productId._id, item.size, item.quantity + 1)}>+</button>
            </div>
          </div>
          <div className="cart-item-price">
            ${item.productId.price * item.quantity}
          </div>
          <div className="cart-item-remove">
            <button className="remove-btn" onClick={() => updateQuantity(item.productId._id, item.size, 0)}>Remove</button>
          </div>
        </div>
      ))}
      <div className="cart-summary">
        <h3>Summary</h3>
        <p>Total Amount: ${cart.items.reduce((total, item) => total + item.productId.price * item.quantity, 0)}</p>
        
        {/* Telefon Numarası Girişi */}
        <div className="phone-input-container">
          <label htmlFor="phoneNumber">Sizinle iletişime geçmemiz için lütfen Doğru Telefon numaranızı Bağlantı kodu ile birlikte girerek Yazınız:</label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="+905555555555" 
          />
        </div>
        
        {/* Sipariş Butonu */}
        <button 
          className="checkout-btn" 
          onClick={handleCheckout} 
          disabled={!isOrderButtonActive} 
        >
          Siparişi Geç
        </button>
      </div>
    </div>
  );
};

export default Cart;
