import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // useNavigate'i ekledik
import { Container, Row, Col } from 'react-bootstrap'; // React Bootstrap'ten import ettik
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import HeartButton from './HeartButton'; // HeartButton'ı ekledik
import Button from '@mui/joy/Button'; // Button'u ekledik
import './CollectionProducts.css';

const CollectionProducts = () => {
    const { collectionName } = useParams();
    const [products, setProducts] = useState([]);
    const navigate = useNavigate(); // useNavigate'i başlattık

    useEffect(() => {
        fetchCollectionProducts();
    }, [collectionName]);

    const fetchCollectionProducts = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/products/collection/${collectionName}`);
            setProducts(res.data);
        } catch (error) {
            console.error('Error fetching collection products:', error);
        }
    };

    const handleNavigateToProduct = (productId) => {
        navigate(`/product/${productId}`);
    };

    return (
        <Container className="my-5">
            <h2 className="section-title">{collectionName} Koleksiyonu</h2>
            <Row>
                {products.length > 0 ? (
                    products.map((product, index) => (
                        <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                            <Card 
                                sx={{ width: 320, maxWidth: '100%', boxShadow: 'lg', height: 450, cursor: 'pointer' }} 
                                onClick={() => handleNavigateToProduct(product._id)}
                            >
                                <CardOverflow sx={{ position: 'relative' }}>
                                    <AspectRatio ratio="20/19" sx={{ height: 250 }}>
                                        <img
                                            src={product.mainImageUrl || 'https://via.placeholder.com/320x200'}
                                            alt={product.name}
                                            loading="lazy"
                                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                        />
                                        <HeartButton 
                                            productId={product._id} 
                                            style={{ position: 'absolute', top: 8, right: 8 }} 
                                            onClick={(e) => e.stopPropagation()} // Kart tıklamasını tetiklemesin
                                        />
                                    </AspectRatio>
                                </CardOverflow>
                                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    <Typography level="body-xs" sx={{ minHeight: 20 }}>
                                        {product.description || ' '}
                                    </Typography>
                                    <Typography
                                        level="body-md"
                                        fontWeight="bold"
                                        sx={{ mt: 1 }}
                                    >
                                        {product.name}
                                    </Typography>
                                    <Typography
                                        level="title-lg"
                                        sx={{ mt: 1, fontWeight: 'xl' }}
                                    >
                                        {product.price} $
                                    </Typography>
                                </CardContent>
                                <CardOverflow>
                                    <Button 
                                        variant="solid" 
                                        color="primary" 
                                        size="lg" 
                                        sx={{ width: '100%' }}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Kart tıklamasını tetiklemesin
                                            handleNavigateToProduct(product._id); // Sepete ekleme işlemi yapılırken ürün sayfasına yönlendirsin
                                        }} 
                                    >
                                        Add to cart
                                    </Button>
                                </CardOverflow>
                            </Card>
                        </Col>
                    ))
                ) : (
                    <p>Koleksiyona ait ürün bulunmamaktadır.</p>
                )}
            </Row>
        </Container>
    );
};

export default CollectionProducts;
