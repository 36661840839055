import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaTachometerAlt, FaCogs, FaUserAlt, FaSignOutAlt } from 'react-icons/fa';
import './Sidebar.css';
import logo from './logo.png'; // Logoyu import edin

const AdminSidebar = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    return (
        <div className="sidebar">
            <div className="sidebar-logo">
                <NavLink to="/">
                    <img src={logo} alt="Logo" />
                </NavLink>
            </div>
            <NavLink to="/admin/dashboard" className="sidebar-link" activeclassname="active">
                <FaTachometerAlt /> Dashboard
            </NavLink>
            <NavLink to="/admin/products" className="sidebar-link" activeclassname="active">
                <FaCogs /> Ürünler
            </NavLink>
            <NavLink to="/admin/customers" className="sidebar-link" activeclassname="active">
                <FaUserAlt /> Müşteriler
            </NavLink>
            <NavLink to="/admin/collections" className="sidebar-link" activeclassname="active">
                <FaUserAlt /> Koleksiyonlar
            </NavLink>
            <NavLink to="/admin/orders" className="sidebar-link" activeclassname="active">
                <FaUserAlt /> Siparişler
            </NavLink>
            <NavLink to="/admin/add-banner" className="sidebar-link" activeclassname="active">
                <FaCogs /> Banner Ekle
            </NavLink>
            <div className="sidebar-link" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                <FaSignOutAlt /> Çıkış yap
            </div>
        </div>
    );
};

export default AdminSidebar;
