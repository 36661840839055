import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Yönlendirme için import
import { Container, Row, Col } from 'react-bootstrap';
import HeartButton from './HeartButton';  // Doğru yolu kontrol et
import './ProductList.css';
import ProductCard from './ProductCard';


const ProductList = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate(); // Yönlendirme için hook

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
            setProducts(res.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handleProductClick = (id) => {
        navigate(`/product/${id}`); // Ürün detay sayfasına yönlendirme
    };

    return (
        <Container className="my-5">
          <h2 className="section-title">Ürünler</h2>
          <Row>
            {products.map((product, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                <ProductCard product={product} onProductClick={handleProductClick} />
              </Col>
            ))}
          </Row>
        </Container>
      );
      
};

export default ProductList;
