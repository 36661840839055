import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import { Col, Row, Container } from 'react-bootstrap';
import './CategoryCard.css';

const CategoryCard = () => {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/collections`);
            setCategories(res.data);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    const handleNavigateToCategory = (categoryName) => {
        navigate(`/collections/${categoryName}`);
    };

    return (
        <Container className="my-5">
            <h2 className="section-title">Koleksiyonlar</h2>
            <Row>
                {categories.map((category, index) => (
                    <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                        <Card 
                            sx={{ width: 300, maxWidth: '100%', boxShadow: 'lg', height: 380, cursor: 'pointer' }} 
                            onClick={() => handleNavigateToCategory(category.name)}
                        >
                            <CardOverflow sx={{ position: 'relative' }}>
                                <AspectRatio ratio="20/19" sx={{ height: 220 }}>
                                    <img
                                        src={category.imageUrl || 'https://via.placeholder.com/320x200'}
                                        alt={category.name}
                                        loading="lazy"
                                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                    />
                                </AspectRatio>
                            </CardOverflow>
                            <CardContent 
                                sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    textAlign: 'center', 
                                    flexGrow: 1, 
                                    padding: '16px 8px'  // Yukarıdan ve aşağıdan boşluk bırakıldı
                                }}
                            >
                                <Typography level="body-xs" sx={{ fontSize: '0.85rem' }}>
                                    {category.description || ' '}
                                </Typography>
                                <Typography
                                    level="body-md"
                                    fontWeight="bold"
                                    sx={{ mt: 0.5, fontSize: '1.1rem' }}
                                >
                                    {category.name}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default CategoryCard;
