import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SignupForm = ({ setAuth, setAdmin }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, { name, email, password });
            localStorage.setItem('token', res.data.token);
            setAuth(true);
            const decoded = JSON.parse(atob(res.data.token.split('.')[1]));
            setAdmin(decoded.user.isAdmin);
            navigate('/');
        } catch (err) {
            console.error(err.response ? err.response.data : err.message);
        }
    };

    return (
        <div style={styles.container}>
            <h2>Üye Ol</h2>
            <form style={styles.form} onSubmit={handleSubmit}>
                <div style={styles.formGroup}>
                    <label>Ad</label>
                    <input type="text" placeholder="Ad" value={name} onChange={(e) => setName(e.target.value)} style={styles.input} />
                </div>
                <div style={styles.formGroup}>
                    <label>Email adresi</label>
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} style={styles.input} />
                </div>
                <div style={styles.formGroup}>
                    <label>Şifre</label>
                    <input type="password" placeholder="Şifre" value={password} onChange={(e) => setPassword(e.target.value)} style={styles.input} />
                </div>
                <div style={styles.formGroup}>
                    <label>Şifreyi tekrar girin</label>
                    <input type="password" placeholder="Şifreyi tekrar girin" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} style={styles.input} />
                </div>
                <button type="submit" style={styles.button}>Üye Ol</button>
            </form>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        maxWidth: '400px',
        margin: 'auto',
        marginTop: '2rem'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    formGroup: {
        marginBottom: '1rem'
    },
    input: {
        width: '100%',
        padding: '0.5rem',
        borderRadius: '4px',
        border: '1px solid #ccc',
        boxSizing: 'border-box'
    },
    button: {
        padding: '0.75rem',
        borderRadius: '4px',
        border: 'none',
        backgroundColor: '#3B71CA',
        color: 'white',
        cursor: 'pointer',
        marginBottom: '1rem'
    }
};

export default SignupForm;
