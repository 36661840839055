import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css'; // Özel stiller için CSS dosyası
import logo from './logo.png'; // Logoyu import edin
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHeart, faShoppingCart, faSearch, faGlobe } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ isAuth, isAdmin, setAuth }) => {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(false);
    navigate('/');
  };

  return (
    <>
      <header className={`main-navbar ${scrolled ? 'scrolled' : ''}`}>
        <div className="top-navbar">
          <div className="search-bar">
            <input type="text" placeholder="Sneaker, Terlik, Loafer ara..." />
            <button type="submit">
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
          <div className="logo" onClick={() => navigate('/')}>
            <img src={logo} alt="Logo" />
          </div>
          <div className="icons">
            {isAuth ? (
              <>
                <FontAwesomeIcon icon={faUser} onClick={() => navigate('/profile')} className="icon" />
                <FontAwesomeIcon icon={faHeart} onClick={() => navigate('/favorites')} className="icon" />
                <FontAwesomeIcon icon={faShoppingCart} onClick={() => navigate('/cart')} className="icon" />
                <div className="language-selection">
                  <FontAwesomeIcon icon={faGlobe} />
                  <span>TR</span>
                </div>
                {isAdmin && <button className="admin-button" onClick={() => navigate('/admin/dashboard')}>Admin</button>}
                <button className="admin-button" onClick={handleLogout}>Çıkış yap</button>
              </>
            ) : (
              <>
                <button className="login-button" onClick={() => navigate('/login')}>Giriş Yap</button>
                <button className="signup-button" onClick={() => navigate('/signup')}>Üye Ol</button>
              </>
            )}
          </div>
        </div>
        <div className="bottom-navbar">
          <nav>
            <a href="#enyeniler">EN YENİLER</a>
            <a href="#kadin">SANDALET</a>
            <a href="#erkek">GÜNLÜK</a>
            <a href="#george-hogg">BABET</a>
            <a href="#outlet">YÜKSEK TOPUK</a>
          </nav>
        </div>
      </header>
      <div className={`scrolled-navbar ${scrolled ? 'visible' : ''}`}>
        <div className="logo-small" onClick={() => navigate('/')}>
          <img src={logo} alt="Logo" />
        </div>
        <nav className="nav-items">
          <a href="#enyeniler">EN YENİLER</a>
          <a href="#kadin">SANDALET</a>
          <a href="#erkek">GÜNLÜK</a>
          <a href="#george-hogg">BABET</a>
          <a href="#outlet">YÜKSEK TOPUK</a>
        </nav>
        <div className="icons">
          <FontAwesomeIcon icon={faSearch} className="icon" />
          {isAuth && (
            <>
              <FontAwesomeIcon icon={faUser} onClick={() => navigate('/profile')} className="icon" />
              <FontAwesomeIcon icon={faHeart} onClick={() => navigate('/favorites')} className="icon" />
              <FontAwesomeIcon icon={faShoppingCart} onClick={() => navigate('/cart')} className="icon" />
              <div className="language-selection">
                <FontAwesomeIcon icon={faGlobe} />
                <span>TR</span>
              </div>
              {isAdmin && <button className="admin-button" onClick={() => navigate('/admin/dashboard')}>Admin</button>}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
