import React from 'react';
import { Carousel, Container } from 'react-bootstrap';

const promotions = [
    "https://via.placeholder.com/150x150",
    "https://via.placeholder.com/150x150",
    "https://via.placeholder.com/150x150",
    "https://via.placeholder.com/150x150",
    "https://via.placeholder.com/150x150",
    "https://via.placeholder.com/150x150",
    "https://via.placeholder.com/150x150",
    "https://via.placeholder.com/150x150",
    "https://via.placeholder.com/150x150",
    "https://via.placeholder.com/150x150"
];

const PromotionCarousel = () => {
    return (
        <Container className="my-5">
            <Carousel>
                {promotions.map((promotion, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={promotion}
                            alt={`Slide ${index}`}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        </Container>
    );
}

export default PromotionCarousel;
