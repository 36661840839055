import React from 'react';
import './Dashboard.css';
import { Container } from 'react-bootstrap';

const Dashboard = () => {
    return (
        <Container fluid className="dashboard">
            <h2>Dashboard</h2>
            <div className="dashboard-cards">
                <div className="card">
                    <h3>Total Revenue</h3>
                    <p>$ 7375.8</p>
                </div>
                <div className="card">
                    <h3>Total Orders</h3>
                    <p>34</p>
                </div>
                <div className="card">
                    <h3>Total Customers</h3>
                    <p>9</p>
                </div>
                <div className="card">
                    <h3>Product Count</h3>
                    <p>120</p>
                </div>
                <div className="card">
                    <h3>Collection Count</h3>
                    <p>5</p>
                </div>
            </div>
        </Container>
    );
};

export default Dashboard;
