// src/components/UserProfile.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css';

const UserProfile = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [userOrders, setUserOrders] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserInfo();
        fetchUserOrders();
    }, []);

    const fetchUserInfo = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/profile`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUserInfo(res.data);
        } catch (error) {
            console.error('Kullanıcı bilgileri alınırken hata:', error);
        }
    };

    const fetchUserOrders = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/orders/user`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUserOrders(res.data);
        } catch (error) {
            console.error('Siparişler alınırken hata:', error);
        }
    };

    if (!userInfo) return <div>Loading...</div>;

    return (
        <div className="user-profile-container">
            <h2>Kullanıcı Profili</h2>
            <div className="user-info">
                <p><strong>İsim:</strong> {userInfo.firstName} {userInfo.lastName}</p>
                <p><strong>E-posta:</strong> {userInfo.email}</p>
                <p><strong>Telefon:</strong> {userInfo.phoneNumber}</p>
            </div>

            <h3>Yaptığınız Siparişler</h3>
            {userOrders.length > 0 ? (
                <table className="order-table">
                    <thead>
                        <tr>
                            <th>Sipariş ID</th>
                            <th>Tarih</th>
                            <th>Toplam Tutar</th>
                            <th>Durum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userOrders.map((order) => (
                            <tr key={order._id} onClick={() => navigate(`/orders/${order._id}`)}>
                                <td>{order._id}</td>
                                <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                                <td>{order.totalAmount} $</td>
                                <td>{order.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Henüz bir sipariş bulunmamaktadır.</p>
            )}
        </div>
    );
};

export default UserProfile;
