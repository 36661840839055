import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import ProductCard from './ProductCard'; // ProductCard bileşenini import edin

const FavoritesPage = () => {
    const [favorites, setFavorites] = useState([]);

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) return;

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/favorites`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setFavorites(response.data);
            } catch (error) {
                console.error('Favori ürünler getirilirken hata:', error);
            }
        };

        fetchFavorites();
    }, []);

    if (favorites.length === 0) return <div>Favori ürün bulunmuyor.</div>;

    return (
        <Container className="my-5">
            <h2 className="section-title">Favoriler</h2>
            <Row>
                {favorites.map((product, index) => (
                    <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                        <ProductCard product={product} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default FavoritesPage;
