import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './HeartButton.css'; // Assuming you have a CSS file for styling

const HeartButton = ({ productId }) => {
    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        checkIfFavorite();
    }, []);

    const checkIfFavorite = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/favorites/${productId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`, // Assuming you're storing token in localStorage
                },
            });
            setIsFavorite(response.data.isFavorite);
        } catch (error) {
            console.error('Favori durumu kontrol edilirken hata:', error);
        }
    };

    const toggleFavorite = async () => {
        try {
            if (isFavorite) {
                await axios.delete(`${process.env.REACT_APP_API_URL}/favorites/remove`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                    data: { productId },
                });
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/favorites/add`, { productId }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
            }
            setIsFavorite(!isFavorite);
        } catch (error) {
            console.error('Favori durumu güncellenirken hata:', error);
        }
    };

    return (
        <button
            className={`heart-button ${isFavorite ? 'favorite' : ''}`}
            onClick={(e) => {
                e.stopPropagation(); // Prevent navigating to product page
                toggleFavorite();
            }}
        >
            {isFavorite ? '❤️' : '🤍'}
        </button>
    );
};

export default HeartButton;
