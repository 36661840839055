import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './ProductDetail.css';

const ProductDetail = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [selectedColor, setSelectedColor] = useState('');
    const [showDescription, setShowDescription] = useState(false);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`);
                setProduct(response.data);
                setSelectedImage(response.data.mainImageUrl);
            } catch (error) {
                console.error('Error fetching the product:', error);
            }
        };
        fetchProduct();
    }, [id]);

    if (!product) return <div>Loading...</div>;

    const toggleSizeSelection = (size) => {
        if (selectedSizes.includes(size)) {
            setSelectedSizes(selectedSizes.filter(s => s !== size));
        } else {
            setSelectedSizes([...selectedSizes, size]);
        }
    };

    const handleColorSelection = (color) => {
        setSelectedColor(color);
    };

    const handleAddToCart = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found, please log in.');
                return;
            }
    
            if (!selectedColor) {
                console.error('Please select a color.');
                return;
            }
    
            if (selectedSizes.length === 0) {
                console.error('Please select a size.');
                return;
            }
    
            const cartItems = selectedSizes.map(size => ({
                productId: product._id,
                quantity: 1,
                size: size,
                color: selectedColor // Seçilen renk
            }));
    
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/cart/add`, { 
                productId: product._id, 
                quantity: 1, 
                size: selectedSizes[0], 
                color: selectedColor 
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            console.log('Products added to cart:', response.data);
        } catch (error) {
            console.error('Sepete eklerken hata:', error.response ? error.response.data : error.message);
        }
    };
    
    const handleAddToFavorites = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found, please log in.');
                return;
            }
    
            await axios.post(`${process.env.REACT_APP_API_URL}/favorites/add`, {
                productId: product._id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            console.log('Product added to favorites');
        } catch (error) {
            console.error('Favorilere eklerken hata:', error.response ? error.response.data : error.message);
        }
    };
    
    

    return (
        <div className="product-detail">
            <div className="product-images">
                <div className="main-image">
                    <img src={selectedImage} alt={product.name} />
                </div>
                <div className="thumbnail-images">
                    {product.imageUrls.map((img, index) => (
                        <img
                            key={index}
                            src={img}
                            alt={product.name}
                            onClick={() => setSelectedImage(img)}
                            className={img === selectedImage ? 'active' : ''}
                        />
                    ))}
                </div>
            </div>
            <div className="product-info">
                <h1>{product.name}</h1>
                <p className="price">{product.price} TL</p>
                
                <div className="size-selection">
                    <p>Beden Seçimi:</p>
                    <div className="sizes">
                        {product.sizes.map((size, index) => (
                            <span
                                key={index}
                                className={selectedSizes.includes(size) ? 'size-selected' : ''}
                                onClick={() => toggleSizeSelection(size)}
                            >
                                {size}
                            </span>
                        ))}
                    </div>
                </div>

                <div className="color-selection">
                    <p>Renk Seçimi:</p>
                    <div className="colors">
                        {product.colors && product.colors.map((color, index) => (
                            <span
                                key={index}
                                className={selectedColor === color ? 'color-selected' : ''}
                                onClick={() => handleColorSelection(color)}
                            >
                                {color}
                            </span>
                        ))}
                    </div>
                </div>

                <button className="add-to-cart" onClick={handleAddToCart}>Sepete Ekle</button>
                <div className="product-description">
                    <div className="description-header" onClick={() => setShowDescription(!showDescription)}>
                        <h3>Ürün Açıklaması</h3>
                        <span>{showDescription ? '-' : '+'}</span>
                    </div>
                    {showDescription && <p>{product.description || 'Ürün açıklaması bulunmamaktadır.'}</p>}
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;
