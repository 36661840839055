import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebar from './AdminSidebar'; // AdminSidebar bileşenini import edin

const AdminLayout = () => {
    return (
        <div className="d-flex" style={{ width: '100%' }}>
            <AdminSidebar />
            <main className="flex-grow-1" style={{ marginLeft: '200px', padding: '20px', width: 'calc(100% - 200px)' }}> {/* Sidebar genişliği kadar margin ekleyin */}
                <Outlet />
            </main>
        </div>
    );
};

export default AdminLayout;
